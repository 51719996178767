import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

import "./index.scss"; // Change import after CSS changes.

import App from "./App";

import config from "./config";
import { history } from "./utils";

ReactGA.initialize("UA-166910418-1");

ReactGA.pageview(window.location.pathname + window.location.search);

history.listen((location, action) => {
  ReactGA.pageview(location.pathname + location.search);
});

// A function that routes the user to the right place
// after login

const onRedirectCallback = (appState) => {
  history.replace(
    appState && appState.returnTo
      ? appState.returnTo
      : window.location.pathname
  );
};

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <BrowserRouter>
      <React.StrictMode>
        <Auth0Provider
          domain={config.REACT_APP_AUTH0_DOMAIN}
          clientId={config.REACT_APP_AUTH0_CLIENTID}
          redirectUri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
          audience={config.REACT_APP_AUTH0_AUDIENCE}
          scope={config.REACT_APP_AUTH0_SCOPE}
          useRefreshTokens={true}
        >
          <App />
        </Auth0Provider>
      </React.StrictMode>
    </BrowserRouter>,
    rootElement
  );
} else {
  ReactDOM.render(
    <BrowserRouter>
      <React.StrictMode>
        <Auth0Provider
          domain={config.REACT_APP_AUTH0_DOMAIN}
          clientId={config.REACT_APP_AUTH0_CLIENTID}
          redirectUri={window.location.origin}
          onRedirectCallback={onRedirectCallback}
          audience={config.REACT_APP_AUTH0_AUDIENCE}
          scope={config.REACT_APP_AUTH0_SCOPE}
          useRefreshTokens={true}
        >
          <App />
        </Auth0Provider>
      </React.StrictMode>
    </BrowserRouter>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
