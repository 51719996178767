import React, { useState } from "react";

import { Alert, Collapse } from "react-bootstrap";

import styles from "./warning.module.css";
// get our fontawesome imports
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DesignerWarning = () => {
  const [show, setShow] = useState(true);
  //const history = useHistory();
  window.setTimeout(() => {
    setShow(false);
  }, 12000);
  //   const gotoAlertPage = () => {
  //     ReactGA.event({
  //       category: "Button Click",
  //       action: "COVID-19 Banner Click",
  //     });
  //     history.push("/corona");
  //   };

  return (
    <>
      {show && (
        <Alert
          className={styles.notification}
          variant="danger"
          onClose={() => setShow(false)}
          transition={Collapse}
          dismissible
        >
          <table>
            <tr>
              <td style={{ paddingRight: "10px" }}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </td>
              <td>
                You need to use Designer every 24 hours to keep it running.
              </td>
            </tr>
          </table>
        </Alert>
      )}
    </>
  );
};

export default DesignerWarning;
