import React from "react";
import { Button, ProgressBar, Spinner } from "react-bootstrap";

import classes from "./Modal.module.css";

const Modal = (props) => {
  return (
    <div className={classes.Container} onClick={(e)=>onClickOutside(e,props)}>
      <div className={classes.Modal} onClick={(e)=>e.stopPropagation()}>
        {!props.isLoading &&
        <div>
          <p>{props.message}</p>
          {props.variant==='confirm' && (
          <div className={classes.ButtonGroup}>
            <Button variant="outline-secondary" onClick={(e)=>onCancelClicked(e,props)}>Cancel</Button>
            <Button variant="primary" onClick={(e)=>onConfirmClicked(e,props)}>Yes</Button>
          </div>)}
        </div>}
        {props.isLoading &&
        <div className={classes.ProgressContainer}>
            <p>{props.loadingMessage?props.loadingMessage:"Loading..."}</p>
              <ProgressBar animated now={70}/>
        </div>}

      </div>
    </div>
  );
};


const onConfirmClicked=(e, props)=>{
    e.stopPropagation();
    if(props.onConfirmClicked)
        props.onConfirmClicked(e);
  
}

const onCancelClicked=(e, props)=>{
    e.stopPropagation();
    if(props.onCancelClicked)
        props.onCancelClicked(e);  
}

const onClickOutside=(e,props)=>{
    e.stopPropagation();
    if(props.onClickOutside)
      props.onClickOutside(e);  
}

export default Modal;