import React from 'react';
import { Helmet } from "react-helmet";

const seo = (props) => {
    return (
        <Helmet>
            {props.title ? <title> {props.title} </title> : undefined}
            {props.author ? <meta name="author" content={props.author} /> : undefined}
            {props.description ? <meta property="description" content={props.description} /> : undefined}
            {props.twitterTitle ? <meta property="twitter:title" content={props.twitterTitle} /> : undefined}
            {props.twitterImage ? <meta property="twitter:image" content={props.twitterImage} /> : undefined}
            {props.twitterDescription ? <meta name="twitter:description" content={props.twitterDescription} /> : undefined}
            {props.ogImage ? <meta name="image" property="og:image" content={props.ogImage} /> : undefined}
            {props.ogTitle ? <meta name="title" property="og:title" content={props.ogTitle} /> : undefined}
            {props.ogDescription ? <meta name="description" property="og:description" content={props.ogDescription} /> : undefined}
            {props.ogType ? <meta property="og:type" content={props.ogType} /> : undefined}
        </Helmet>
    );
}

export default seo;