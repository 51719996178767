import Cookies from "universal-cookie";

import config from "../config";

export const deleteCookies = () => {
  const cookies = new Cookies();
  cookies.remove("designer.preference");
  cookies.remove("designer.preferenceID");
  cookies.remove("designer.jwt.token", {
    domain: config.REACT_APP_COOKIE_URL,
    secure: config.REACT_APP_SECURE_COOKIE === "false" ? false : true,
    //sameSite: "none",
  });
};
