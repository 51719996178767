import React, { useState } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { withRouter } from "react-router-dom";

import PDDesigner from "./components/PDDesigner/PDDesigner";
import PDDashboard from "./components/PDDesigner/PDDashboard";
// --- deprecated ----
// import Home from "./components/Home/Home";
// import Designer from "./components/Designer/Designer";
// import Security from "./components/Security/Security";
// import CaseStudies from "./components/CaseStudies/CaseStudies";
// import CaseStudy from "./components/CaseStudy/CaseStudy";
// import WhitePapers from "./components/WhitePapers/WhitePapers";
// import AdoptionProgram from "./components/Adoption/Adoption";
// import Contact from "./components/Contact/Contact";
// import WhyPrescient from "./components/WhyPrescient/WhyPrescient";
// import Blogs from "./components/Blogs/Blogs";
// import About from "./components/About/About";

// import BlogDetails from "./components/Blogs/BlogDetails";
// import Login from "./components/Login/Login";
// import Careers from "./components/Careers/Careers";
// import PrivacyPolicy from "./components/PrivacyPolicy/Privacypolicy";
// import Corona from "./components/CovidResponse/CovidResponse";
// import OpenPositions from "./components/OpenPositions/OpenPositions";
// --- deprecated ----
import Documentations from "./components/Documentations/Documentations";
import OpenDocumentations from "./components/Documentations/OpenDocumentations";
import NoMatch from "./components/NoMatch/NoMatch";

import NavBar from "./components/UI/Navbar/NavBar";
import Footer from "./components/UI/Footer/Footer";

import AppContext from "./context/app";
import config from "./config";
import { ScrollToTop } from "./hooks";
import { history, deleteCookies } from "./utils";

import "./App.css";
import Terms from "./components/Terms/Terms";

const App = withRouter(({ location }) => {
  //Hubspot chat bot
  React.useEffect(() => {
    (function loadHubSpot() {
      const d = document;
      const s = d.createElement("script");

      s.src = "https://js.hs-scripts.com/7099836.js";
      s.async = true;
      d.getElementsByTagName("body")[0].appendChild(s);
    })();
  }, []);
  //Hubspot chat bot

  const { loading, logout } = useAuth0();

  const gotoContactPage = () => {
    history.push("/contact");
  };

  const gotoDesignerPage = () => {
    window.open("/pddesigner");
  };

  const gotoDashboardPage = () => {
    window.open("/pddashboard");
  };

  const gotoProductPage = () => {
    history.push("/designer");
  };

  const gotoOpenPositions = () => {
    history.push("/open-positions");
  };

  const gotoBlogPost = (blogId, slug) => {
    history.push(`/blog/${blogId}`);
    // With slug in URL.
    // history.push(`/blog/${blogId}/${slug}`);
  };

  const gotoCaseStudy = (id, slug) => {
    history.push(`/case-studies/${id}/${slug}`);
  };

  const gotoDocumentation = (docId) => {
    history.push(`/documentation/${docId}`);
  };

  const gotoOpenDocumentation = (docId) => {
    history.push(`/xqwxvrnxmhgem7cxddtyxxc/${docId}`);
  };
  const gotoLoginPage = () => {
    history.push("/login");
  };

  const logoutPD = () => {
    deleteCookies();
    logout({ returnTo: config.REACT_APP_PUBLIC_URL, federated: true });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AppContext.Provider
      value={{
        gotoContactPage: gotoContactPage,
        gotoDesignerPage: gotoDesignerPage,
        gotoDashboardPage: gotoDashboardPage,
        gotoLoginPage: gotoLoginPage,
        gotoOpenPositions: gotoOpenPositions,
        gotoProductPage: gotoProductPage,
        gotoBlogPost: gotoBlogPost,
        gotoCaseStudy: gotoCaseStudy,
        gotoDocumentation: gotoDocumentation,
        gotoOpenDocumentation: gotoOpenDocumentation,
        logoutPD: logoutPD,
      }}
    >
      <Router history={history}>
        <ScrollToTop />
        <div>
          <Switch>
            <Route exact path="/login" component={PDDesigner} />
            <Route path="/pddesigner" component={PDDesigner} />
            <Route exact path="/" component={PDDesigner} />
            <Route path="/pddashboard" component={PDDashboard} />
            <Route
              path="/xqwxvrnxmhgem7cxddtyxxc/:id?"
              component={OpenDocumentations}
            />

            <Route
              exact
              path="/documentation/:id?"
              component={Documentations}
            />
            <Route path="/terms" component={Terms} />
            <Route>
              <NavBar />
              <Switch>
                {/* <Route exact path="/" component={PDDesigner} />
                <Route path='/product' component={Product} /> */}
                {/* <Route path="/contact" component={Contact} />
                <Route path="/about" component={About} /> */}
                {/* <Route path="/login" component={Login} /> */}
                {/* <Route path="/designer" component={Designer} />
                <Route path="/technology" component={Designer} />
                <Route path="/security" component={Security} />
                <Route
                  exact
                  path="/case-studies/:id/:slug"
                  component={CaseStudy}
                /> */}
                {/* <Route path="/case-studies" component={CaseStudies} />
                <Route path="/white-papers" component={WhitePapers} />
                <Route path="/adoption" component={AdoptionProgram} />
                <Route path="/why-prescient" component={WhyPrescient} />
                <Route path="/privacy" component={PrivacyPolicy} />
                <Route path="/careers" component={Careers} />
                <Route exact path="/blog/:id" component={BlogDetails} /> */}
                {/* <Route exact path="/blog/:id/:slug" component={BlogDetails} /> */}
                {/* <Route exact path="/blog" component={Blogs} />
                <Route exact path="/open-positions" component={OpenPositions} /> */}

                {/* <Route exact path="/documentation" component={Login} /> */}
                {/* <Route path="/corona" component={Corona} /> */}

                <Route component={NoMatch} />
              </Switch>
              <Footer />
            </Route>
          </Switch>
        </div>
      </Router>
    </AppContext.Provider>
  );
});

export default App;
