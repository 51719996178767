import React, { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import { useLocation, useHistory } from "react-router-dom";
import { NavDropdown, Navbar, Nav, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useNotification } from "../../hooks";
import Modal from "../UI/Modal/Modal";
import AppContext from "../../context/app";
import config, { USERS_WHITELIST } from "../../config";
import { Scrollbars } from "react-custom-scrollbars";

import styles from "./DesignerNavBar.module.css";
import { faKaaba } from "@fortawesome/free-solid-svg-icons";
import Notification from "../UI/Notification/Notification";

const cookies = new Cookies();

const NavBar = (props) => {
  const [loading, setLoading] = useState(false);
  const { notificationProps, showNotification } = useNotification();
  const { isAuthenticated, loginWithRedirect, user, getAccessTokenSilently } =
    useAuth0();
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  const history = useHistory();

  const UPDATE_MESSAGE =
    "Would you like to Update Designer? This operation might take a few minutes during which Designer will be inaccessible. Any changes that have not been deployed will be lost. After Designer update the browser window will automatically refresh.";

  useEffect(() => {
    setExpanded(false);
  }, [pathname]);

  //const [appId, setAppId] = useState(null);
  const [app_designers, setDesigners] = React.useState([]);
  useEffect(async () => {
    if (!loading && user) {
      const getUserMetadata = async () => {
        const accessToken = await getAccessTokenSilently();
        let user_id = user.sub.split("|")[1];

        const userDetailsByIdUrl =
          config.REACT_APP_BACKEND_API + "/getuser/" + user_id;
        //const userDetailsByIdUrl = `https://${config.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        let user_app_data = await metadataResponse.json();
        //console.log(user_app_data);
        return user_app_data;
      };
      var data = await getUserMetadata();
      //const appId = data.designer_path;
      //setAppId(appId);
      // console.log(data.designers);
      setDesigners(data.designers);
    }
  }, [pathname]);

  const confirmUpdateDesigner = () => {
    updateDesigner(true);
    setShowModal(true);
    onMenuClicked();
  };

  const updateDesigner = (checkForUpdates) => {
    var selected_designer_id = cookies.get("designer.preferenceID");
    //alert(designer_to_start);
    setIsLoading(true);
    let isUpdateAvailable = false;
    if (!checkForUpdates) {
      isUpdateAvailable = localStorage.getItem("updateAvailable");
      setLoadingMessage("Updating Designer");
    }

    updateDesignerFromAWS(selected_designer_id, isUpdateAvailable).then(
      ([data]) => {
        setIsLoading(false);
        setShowModal(false);
        // both have loaded!
        //console.log(data);
        if (data.status == 400) {
          showNotification(data.message);
          setShowModal(false);
        } else if (data.status == 200) {
          //Confirm Box with the message and then reload
          /*
        Would you like to Update Designer?       
This operation might take a few minutes during which Designer will be inaccessible
Any changes that have not been deployed will be lost
After Designer update the browser window will automatically refresh
        */
          if (checkForUpdates) {
            if (!!data.update_available) {
              localStorage.setItem("updateAvailable", !!data.update_available);
              setShowModal(true);
            } else {
              localStorage.setItem("updateAvailable", false);
              showNotification(data.message);
            }
          } else {
            window.location.reload();
          }
        }
      },
      () => {
        setIsLoading(false);
        setShowModal(false);
      }
    );
  };

  function updateDesignerFromAWS(selected_designer_id, isUpdateAvailable) {
    return Promise.all([callUpdate(selected_designer_id, isUpdateAvailable)]);
  }

  async function callUpdate(selected_designer_id, isUpdateAvailable) {
    //var user_designer_id = cookies.get("designer.preference");
    const accessToken = await getAccessTokenSilently();
    let data = "";
    if (isUpdateAvailable) {
      data = "update_available=" + isUpdateAvailable;
    }

    const requestOptions = {
      method: "POST",
      headers: new Headers({
        authtoken: selected_designer_id,
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: data,
    };

    return fetch(
      config.REACT_APP_BACKEND_API + "/updatedesigner",
      requestOptions
    ).then((response) => response.json());
  }

  const gotoContactPage = () => {
    window.open(
      "https://www.prescientdevices.com/en-us/contact-us-prescient-devices"
    );
  };

  const gotoDocumentationPage = () => {
    window.open("https://docs.prescientdevices.com");
  };

  const onToggle = () => {
    setExpanded((expanded) => !expanded);
  };

  const onDropDownItemSelect = () => {
    setExpanded(false);
  };

  const getSelectedItem = () => {
    try {
      var selected_designer_path = cookies.get("designer.preference");
      var designer_link = document.getElementById(selected_designer_path);
      designer_link.style = "color: white !important;";
      designer_link.style.backgroundColor = "#003C83";
    } catch (error) {
      console.log(error);
    }
  };

  const setDesignerPreferenceCookie = (designer_path, designer_id) => {
    cookies.set("designer.preference", designer_path);
    cookies.set("designer.preferenceID", designer_id);
    window.location.reload();
  };

  const ref = useRef(null);

  const onMenuClicked = () => {
    setOpen((toggle) => !toggle);
    if (!!props.OnMenuToggled)
      props.OnMenuToggled(open, ref.current ? ref.current.offsetWidth : 0);
  };

  return (
    <>
      <Notification {...notificationProps} />
      <div className={styles.menuToggleButton} onClick={onMenuClicked}>
        <p>{open ? String.fromCharCode(8592) : String.fromCharCode(8594)}</p>
      </div>
      <div
        className={`${styles.sideBar} ${open === true ? styles.open : ""}`}
        ref={ref}
      >
        <div className={styles.logo}>
          <img
            src={config.REACT_APP_PUBLIC_URL + "assets/img/logo-white.png"}
          />
        </div>
        <div>
          {isAuthenticated &&
            (props.variant === "dashboard" ? (
              <AppContext.Consumer>
                {(context) => (
                  <div
                    className={styles.buttonContainer}
                    onClick={context.gotoDesignerPage}
                  >
                    <p>Designer</p>
                  </div>
                )}
              </AppContext.Consumer>
            ) : (
              <AppContext.Consumer>
                {(context) => (
                  <div
                    className={styles.buttonContainer}
                    onClick={context.gotoDashboardPage}
                  >
                    <p>Dashboard</p>
                  </div>
                )}
              </AppContext.Consumer>
            ))}

          {isAuthenticated &&
            (props.variant === "documentation" ? (
              <AppContext.Consumer>
                {(context) => (
                  <div
                    className={styles.buttonContainer}
                    onClick={context.gotoDesignerPage}
                  >
                    <p>Designer</p>
                  </div>
                )}
              </AppContext.Consumer>
            ) : (
              <AppContext.Consumer>
                {(context) => (
                  <div
                    className={`${styles.buttonContainer} ${styles.documentation}`}
                    onClick={gotoDocumentationPage}
                  >
                    <p>Documentation</p>
                  </div>
                )}
              </AppContext.Consumer>
            ))}
        </div>
        <div className={`${styles.buttonContainer} ${styles.designer}`}>
          <p>Designers</p>
        </div>
        {/* {app_designers.length > 1 ? ( */}
        <div className={styles.designerContainer}>
          {/* <p className={styles.title}>Designer</p> */}
          {/* <div className={`${styles.buttonContainer} ${styles.designer}`}>
              <p>Designers</p>
            </div> */}
          <Scrollbars
            renderThumbVertical={(props) => (
              <div {...props} className={styles.thumbVertical} />
            )}
          >
            {/* <div className={`${styles.buttonContainer} ${styles.designer}`}>
              <p>Designers</p>
            </div> */}

            {app_designers.map(
              ({ designer_name, designer_path, designer_id }) => (
                <LinkContainer
                  key={designer_path}
                  to={{
                    pathname: "/pddesigner",
                    state: {
                      path: designer_path,
                    },
                  }}
                  onClick={() =>
                    setDesignerPreferenceCookie(designer_path, designer_id)
                  }
                  // to="/pddesigner"
                >
                  <p
                    key={designer_path}
                    id={designer_path}
                    className={`${styles.designerItem} ${
                      cookies.get("designer.preference") === designer_path
                        ? styles.active
                        : ""
                    }`}
                  >
                    {designer_name}
                  </p>
                </LinkContainer>
              )
            )}
          </Scrollbars>
        </div>
        {/* ) : (  <div className={styles.designerContainerEmpty}></div>
       )}*/}
        <div>
          {isAuthenticated &&
            props.variant != "documentation" &&
            props.variant != "dashboard" && (
              <div
                onClick={confirmUpdateDesigner}
                className={styles.buttonContainer}
              >
                <p>Check for updates</p>
              </div>
            )}
          {isAuthenticated && (
            <AppContext.Consumer>
              {(context) => {
                return (
                  <div
                    className={`${styles.buttonContainer} ${styles.logout}`}
                    onClick={context.logoutPD}
                  >
                    <p>Logout {/* {user.nickname} */}</p>
                  </div>
                );
              }}
            </AppContext.Consumer>
          )}
        </div>
      </div>
      {showModal && (
        <Modal
          variant="confirm"
          message={UPDATE_MESSAGE}
          onConfirmClicked={() => updateDesigner(false)}
          onCancelClicked={() => setShowModal(false)}
          isLoading={isLoading}
          loadingMessage={
            loadingMessage ? loadingMessage : "Looking for updates"
          }
        />
      )}
    </>
  );
};

export default NavBar;
