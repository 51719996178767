import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import Skeleton from "react-loading-skeleton";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import NavBar from "../PDDesigner/DesignerNavBar";
import SEO from "../SEO/SEO";

import AppContext from "../../context/app";
import config from "../../config";

import styles from "./Documentations.module.css";

//Strapi CMS
const Strapi = require("strapi-sdk-javascript").default;
const strapi = new Strapi(config.REACT_APP_API_URL);

class OpenDocumentations extends Component {
  constructor() {
    super();
    this.state = { selectedDocumentation: {}, documentSidebar: [] };
  }

  componentDidMount() {
    (async () => {
      await strapi.login(
        config.REACT_APP_API_USER,
        config.REACT_APP_API_PASSWORD
      );

      const documentSidebar = await strapi.getEntries(
        `documentationsidebars${config.REACT_APP_PRODUCTION == 1 ? "" : ""}`
      );

      if (!!documentSidebar) {
        let selectedDocumentation = Object.assign(
          {},
          this.state.selectedDocumentation
        );
        if (this.props.match.params.id) {
          selectedDocumentation.id = this.props.match.params.id;
          this.getDocument(this.props.match.params.id);
        }
        documentSidebar.map((sidebarItem) => {
          sidebarItem.documentations.map((documentation) => {
            if (!selectedDocumentation.id) {
              selectedDocumentation.id = documentation.id;
              this.getDocument(documentation.id);
            }
          });
        });
        this.setState({ documentSidebar });
      }
    })();
  }

  getDocument(document_id) {
    strapi
      .getEntries(
        `documentations/${document_id}${
          config.REACT_APP_PRODUCTION == 1 ? "?Publish_To_Production=true" : ""
        }`
      )
      .then(
        (document) => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          this.setState({ selectedDocumentation: document });
        },
        (err) => {
          this.props.history.push("/not-found");
        }
      );
  }

  renderDocumentationSidebar(production = true) {
    let selectedDocumentation = Object.assign(
      {},
      this.state.selectedDocumentation
    );
    return this.state.documentSidebar.map((sidebarItem) => {
      return (
        <div key={sidebarItem.id}>
          {sidebarItem.documentations.length > 0 ? (
            <p key={sidebarItem.id}>{sidebarItem.category}</p>
          ) : null}
          {sidebarItem.documentations.map((documentation) => {
            if (production) {
              return documentation.Publish_To_Production ? (
                <AppContext.Consumer>
                  {(context) => {
                    return (
                      <span
                        key={documentation.id}
                        className={`${
                          documentation.id === selectedDocumentation.id
                            ? styles.documentSidebarActive
                            : ""
                        }`}
                        onClick={() => {
                          context.gotoOpenDocumentation(documentation.id);
                          this.getDocument(documentation.id);
                        }}
                      >
                        {documentation.Title}
                      </span>
                    );
                  }}
                </AppContext.Consumer>
              ) : null;
            } else {
              return (
                <AppContext.Consumer>
                  {(context) => {
                    return (
                      <span
                        key={documentation.id}
                        className={`${
                          documentation.id === selectedDocumentation.id
                            ? styles.documentSidebarActive
                            : ""
                        }`}
                        onClick={() => {
                          context.gotoOpenDocumentation(documentation.id);
                          this.getDocument(documentation.id);
                        }}
                      >
                        {documentation.Title}
                      </span>
                    );
                  }}
                </AppContext.Consumer>
              );
            }
          })}
        </div>
      );
    });
  }

  render() {
    return (
      <>
        <SEO title="Prescient Devices, Inc. - Documentation" />
        <NavBar variant="documentation" />
        <Container fluid className={styles.Documentation}>
          <Row className={`justify-content-md-center `}>
            <Col sm="auto" className={styles.documentSidebar}>
              {this.state.documentSidebar.length > 0
                ? this.renderDocumentationSidebar(
                    config.REACT_APP_PRODUCTION === "true"
                  )
                : this.renderDocumentationSidebarLoader()}
            </Col>
            <Col xl={8}>
              {this.state.selectedDocumentation.id ? (
                <Documentation
                  documentation={this.state.selectedDocumentation}
                />
              ) : (
                this.renderDocumentationsLoader()
              )}
            </Col>
          </Row>
          {/* <Row className={styles.Outro}>
              <Col>
                <h1>Join our Team</h1>
                <Button variant="primary" onClick={gotoContactPage}>
                <Button variant="primary">Contact Us</Button>
              </Col>
            </Row> */}
        </Container>
      </>
    );
    // else return this.renderDocumentationsLoader();
  }

  renderDocumentationsLoader = () => {
    return (
      <Col md={12}>
        <Container>
          <Row className={styles.postContainer}>
            <Col>
              <Skeleton height={40} />
              <div style={{ marginTop: "10px" }}>
                <Skeleton count={10} />
              </div>
            </Col>
          </Row>
        </Container>
      </Col>
    );
  };

  renderDocumentationSidebarLoader = () => {
    return (
      <>
        <p>
          <Skeleton width={250} height={18} />
        </p>
        <Skeleton height={14} />
        <Skeleton height={14} />
        <p>
          <Skeleton width={250} height={18} />
          <Skeleton height={14} />
          <Skeleton height={14} />
        </p>
      </>
    );
  };
}

const Documentation = ({ key, documentation }) => {
  return (
    <Row className={`justify-content-md-center `} key={key}>
      <Col md={12}>
        <Container>
          <Row className={styles.postContainer}>
            <Col md={12} lg={12} className={styles.blogText}>
              <p className={styles.postDate}>{documentation.Date}</p>
              <h1>{documentation.Title}</h1>
              <ReactMarkdown escapeHtml={false} source={documentation.Body} />
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};

//export default Documentations;
export default OpenDocumentations;
