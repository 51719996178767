import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";

import SEO from '../SEO/SEO';

import AppContext from '../../context/app';
import config from '../../config';

import styles from "./NoMatch.module.css";

const NoMatch = () => {

  return (
    <>
      <SEO
        title="Prescient Devices, Inc. - 404"
      />
      <div className={styles.main}>
        <Container>
          <Row className={`${styles.Intro}`}>
            <Col md={6}>
              <h1>404</h1>
              <h2>Sorry, we can’t find the page you’re looking for!</h2>
              <p>
                Don’t worry, you can still learn about our technology by heading
                to our <a href="/technology">Product page</a>. And if you’re
                still having trouble accessing our pages,{" "}
                <a href="/contact">we’re here to help!</a>
              </p>
              <AppContext.Consumer>
                {
                  context => <Button onClick={context.gotoProductPage}>See Products</Button>
                }
              </AppContext.Consumer>
            </Col>
            <Col md={6}>
              <img
                alt="intro-banner"
                src={
                  config.REACT_APP_PUBLIC_URL +
                  "assets/img/404/404_logo.png"
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default NoMatch;