import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import config from "../../config";
import SEO from "../SEO/SEO";
import AppContext from "../../context/app";
//import { Link } from "react-router-dom";
import styles from "./Terms.module.css";
import queryString from "query-string";
class Terms extends React.Component {
  constructor() {
    super();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    //const data = new FormData(event.target);

    let url = window.location.href;
    let params = queryString.parse(url);

    let test_url =
      "https://" +
      config.REACT_APP_AUTH0_DOMAIN +
      "/continue?state=" +
      params.state;
    window.location.replace(test_url);
    // window.open(test_url);
    // fetch(
    //   "https://" +
    //     config.REACT_APP_AUTH0_DOMAIN +
    //     "/continue?state=" +
    //     params.state,
    //   {
    //     method: "POST",
    //     body: data,
    //   }
    // );
  }
  render() {
    return (
      <>
        <SEO title="Prescient Devices, Inc. - Terms of Service" />

        <Container className={styles.tnc}>
          {/* <Row>
            <Col style={{ paddingBottom: "20px" }}>
              <h1 className={styles.BlogTitle}>
                Prescient Devices, Inc : Terms of Service
              </h1>
              <p className={`${styles.subtitle} ${styles.postDate}`}>
                Last updated November 1, 2020
              </p>
              <p className={styles.about}>
                These Terms of Service (“<b>Terms of Service</b>”) are entered
                into by and between Prescient Devices, Inc. a Delaware
                corporation (“<b>PDI</b>”) and the entity or person placing an
                order for or accessing any PDI Cloud Services (“Customer” or
                “you”). If you are accessing or using PDI Cloud Services on
                behalf of your company, you represent that you are authorized to
                accept this Agreement on behalf of your company, and all
                references to “you” or “Customer” reference your company.
              </p>
              <p className={styles.about}>
                <b>Agreement; Effective Date:</b> These Terms of Service
                together with all Order Forms, Statements of Work, exhibits and
                addenda hereto, and documents referenced and linked to herein
                (collectively, the “<b>Agreement</b>”) permit Customer to order
                PDI Cloud Services from PDI, sets forth the terms and conditions
                between the parties with respect thereto, and governs Customer’s
                access and use of the PDI Cloud Services. In the event of any
                conflict between the provisions in these Terms of Service and
                any Order Form or Statement of Work, the terms of such Order
                Form or Statement of Work, as applicable, shall prevail. In the
                event of any conflict between the provisions in these Terms of
                Service and any exhibits and addenda hereto or any documents
                referenced and linked to herein, the terms of such exhibits and
                addenda hereto or any documents referenced and linked to herein,
                as applicable, shall prevail. No terms or conditions stated in a
                Customer purchase order or in any other Customer provided
                business forms or order documentation shall be incorporated into
                or form any part of this Agreement, and all such terms or
                conditions shall be null and void. The “<b>Effective Date</b>”
                of the Agreement is the date which is the earlier of (a)
                Customer’s initial access to the PDI Cloud Service through any
                online provisioning, registration or order process with PDI or
                (b) the effective date of the first Order Form that references
                these Terms of Service and is entered into between Customer and
                PDI.
              </p>
              <p className={styles.about}>
                <b>Modifications to Terms of Service:</b> From time to time, PDI
                may modify these Terms of Service; provided, that any
                modifications which are adverse to Customer will not become
                effective until renewal of Customer’s then-current Subscription
                Term or entry into a new Order Form. PDI will use reasonable
                efforts to notify Customer of the changes through communications
                via Customer’s account, email, posting through the PDI Cloud
                Services or other means. Customer may be required to click to
                accept or otherwise agree to the modified Terms of Service
                before renewing a Subscription Term or entering into a new Order
                Form, and in any event continued use of the PDI Cloud Services
                after the updated version of these Terms of Service goes into
                effect will constitute Customer’s acceptance of such updated
                version.{" "}
              </p>

              <p className={styles.about}>
                <b>Orders by Affiliates:</b> The Parties agree that an Affiliate
                of Customer may place orders for the PDI Cloud Services by
                executing a separate Order Form with PDI in which the Customer
                Affiliate agrees to be bound by all terms and conditions of this
                Agreement, in which case all references to "Customer" in the
                Agreement shall mean the Customer Affiliate which executes the
                Order Form; provided, however, that Customer shall remain
                responsible for the compliance of its Affiliates with the terms
                of the Agreement and the Order Forms, including without
                limitation payment of all fees due thereunder.
              </p>
              <p className={styles.about}>
                <b>
                  ACCEPTANCE: BY INDICATING YOUR ACCEPTANCE OF THESE TERMS OF
                  SERVICE OR DOWNLOADING, ACCESSING OR USING ANY PDI CLOUD
                  SERVICES, CUSTOMER AGREES TO BE BOUND BY ALL TERMS AND
                  CONDITIONS OF THE AGREEMENT. IF YOU DO NOT AGREE TO THE
                  AGREEMENT, PLEASE DO NOT USE THE PDI CLOUD SERVICES. FOR
                  CLARITY, EACH PARTY EXPRESSLY AGREES THAT THE AGREEMENT IS
                  LEGALLY BINDING UPON IT. THE AGREEMENT CONTAINS MANDATORY
                  ARBITRATION PROVISIONS THAT REQUIRE THE USE OF ARBITRATION TO
                  RESOLVE DISPUTES, RATHER THAN JURY TRIALS. PLEASE READ IT
                  CAREFULLY.
                </b>
              </p>
            </Col>
          </Row> */}
          <Row className={styles.centered}>
            <Col>
              <h3>
                By clicking "Agree and Continue," you confirm that you read and{" "}
                <br />
                agree to these{" "}
                <a
                  href="https://cloud.prescientdevices.com/en-us/terms-of-service"
                  target="_blank"
                >
                  terms and conditions
                </a>
                .
              </h3>
              <form onSubmit={this.handleSubmit} method="post">
                <Button type="submit" value="Submit">
                  Agree and Continue
                </Button>
              </form>
              <br />
              <AppContext.Consumer>
                {(context) => {
                  return (
                    <label
                      className={styles.goBack}
                      variant="outline-secondary"
                      onClick={context.logoutPD}
                    >
                      Go back to Prescient Devices Home.
                    </label>
                  );
                }}
              </AppContext.Consumer>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
export default Terms;
