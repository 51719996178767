export default {
  NODE_ENV: process.env.NODE_ENV,
  BASE_URL: process.env.BASE_URL,
  REACT_APP_API_URL: process.env.REACT_APP_API_URL,
  REACT_APP_API_USER: process.env.REACT_APP_API_USER,
  REACT_APP_API_PASSWORD: process.env.REACT_APP_API_PASSWORD,
  REACT_APP_PUBLIC_URL: process.env.REACT_APP_PUBLIC_URL,
  REACT_APP_PRODUCTION: process.env.REACT_APP_PRODUCTION,
  REACT_APP_DESIGNER_URL: process.env.REACT_APP_DESIGNER_URL,
  REACT_APP_AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENTID: process.env.REACT_APP_AUTH0_CLIENTID,
  REACT_APP_AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_SCOPE: process.env.REACT_APP_AUTH0_SCOPE,
  REACT_APP_COOKIE_URL: process.env.REACT_APP_COOKIE_URL,
  REACT_APP_SECURE_COOKIE: process.env.REACT_APP_SECURE_COOKIE,
  REACT_APP_MAIN_URL: process.env.REACT_APP_MAIN_URL,
  REACT_APP_BACKEND_API: process.env.REACT_APP_BACKEND_API,
};

export const USERS_WHITELIST = [
  "ashish@prescientdevices.com",
  "kartiksc@andrew.cmu.edu",
  "ashish.yadav@prescientdevices.com",
  "andy.wang@prescientdevices.com",
  "pablo.acosta@prescientdevices.com",
  "annie@prescientdevices.com",
  "ayadav6@ucmerced.edu",
];
