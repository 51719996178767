import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Button, Spinner } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

import AppContext from "../../../context/app";
import config from "../../../config";

import styles from "./NavBar.module.css";

const NavBar = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

  const [expanded, setExpanded] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setExpanded(false);
  }, [pathname]);

  const onToggle = () => {
    setExpanded((expanded) => !expanded);
  };

  const onDropDownItemSelect = () => {
    setExpanded(false);
  };

  return (
    <>
      {isLoading && (
        <Navbar
          collapseOnSelect
          expand="xl"
          className={styles.navBar}
          expanded={expanded}
        >
          <Spinner className={styles.styleSpinner} animation="border" />
        </Navbar>
      )}
      {!isLoading && (
        <Navbar
          collapseOnSelect
          expand="xl"
          className={styles.navBar}
          expanded={expanded}
        >
          <Navbar.Brand href="/">
            <img
              alt="sub-logo"
              src={config.REACT_APP_PUBLIC_URL + "assets/img/logo.png"}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            onClick={onToggle}
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto" />
            <Nav className={styles.navBarRight}>
              <NavDropdown
                title="Product"
                id="collasible-nav-dropdown"
                className={styles.navDropdown}
                rootCloseEvent="click"
                onSelect={onDropDownItemSelect}
              >
                <LinkContainer to="/designer">
                  <Nav.Link>Designer</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/security">
                  <Nav.Link>Security</Nav.Link>
                </LinkContainer>
               
                <LinkContainer to="/why-prescient">
                  <Nav.Link>Why Designer</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/adoption">
                  <Nav.Link>Adoption Program</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/documentation">
                  <Nav.Link>Documentation</Nav.Link>
                </LinkContainer>
              </NavDropdown>
              <NavDropdown
                title="News & Insights"
                id="collasible-nav-dropdown"
                className={styles.navDropdown}
                rootCloseEvent="click"
                onSelect={onDropDownItemSelect}
              >
                
                <LinkContainer to="/blog">
                  <Nav.Link>Blog</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/white-papers">
                  <Nav.Link>White Papers</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/case-studies">
                  <Nav.Link>Case Studies</Nav.Link>
                </LinkContainer>
                
              </NavDropdown>
              <NavDropdown
                title="About"
                id="collasible-nav-dropdown"
                className={styles.navDropdown}
                rootCloseEvent="click"
                onSelect={onDropDownItemSelect}
              >
                <LinkContainer to="/about">
                  <Nav.Link>Team</Nav.Link>
                </LinkContainer>
               
                <LinkContainer to="/careers">
                  <Nav.Link>Careers</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/contact">
                  <Nav.Link>Contact</Nav.Link>
                </LinkContainer>
              </NavDropdown>
              {/* <Link to="/blog">Blog</Link> */}
              {/* <Link to="/about">About</Link> */}
              {isAuthenticated ? (
                <AppContext.Consumer>
                  {(context) => {
                    return (
                      <Button
                        variant="outline-secondary"
                        onClick={context.logoutPD}
                      >
                        Log out
                      </Button>
                    );
                  }}
                </AppContext.Consumer>
              ) : (
                <Button
                  variant="outline-secondary"
                  // onClick={() => loginWithRedirect({})}
                  onClick={() =>
                    loginWithRedirect({ appState: { returnTo: "/pddesigner" } })
                  }
                >
                  Login
                </Button>
              )}
              {isAuthenticated ? (
                <AppContext.Consumer>
                  {(context) => {
                    return (
                      <Button
                        variant="secondary"
                        onClick={context.gotoDesignerPage}
                      >
                        Designer
                      </Button>
                    );
                  }}
                </AppContext.Consumer>
              ) : (
                <AppContext.Consumer>
                  {(context) => {
                    return (
                      <Button
                        variant="secondary"
                        onClick={context.gotoContactPage}
                      >
                        Request a Demo
                      </Button>
                    );
                  }}
                </AppContext.Consumer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      )}
    </>
  );
};

export default NavBar;
