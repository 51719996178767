import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import Cookies from "universal-cookie";

import DesignerWarning from "../UI/Alerts/Designer/warningBanner";
import {
  Accordion,
  useAccordionToggle,
  Spinner,
  Button,
} from "react-bootstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SEO from "../SEO/SEO";
import NavBar from "./DesignerNavBar";
import TopNavBar from "./TopDesignerNavBar";

import config, { USERS_WHITELIST } from "../../config";
import { splitJwtToken } from "../../utils";

import styles from "./PDDesigner.module.css";
import { render } from "react-dom";

const cookies = new Cookies();
let Iframestyle;
let isUserWhiteListed;
function CustomToggle({ expanded, setExpanded, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setExpanded(!expanded);
  });
  return (
    <div
      style={{
        width: 0,
        height: 0,
        borderStyle: "solid",
        borderWidth: "30px 30px 0 0",
        borderColor: "#ffffff transparent transparent transparent",
        position: "fixed",
        zIndex: 9,
      }}
    >
      <SEO
        title="Prescient Designer"
        description="We Enable Customers to Build Competitive Advantages Using IoT"
        twitterTitle="Prescient Designer"
        twitterImage="https://prescientdevices.com/logo192.png"
        twitterDescription="Prescient Designer"
        ogImage="https://prescientdevices.com/logo192.png"
        ogTitle="Prescient Designer"
        ogDescription="We Enable Customers to Build Competitive Advantages Using IoT"
      />
      <FontAwesomeIcon
        style={{
          // backgroundColor: "white",
          position: "fixed",
          fontSize: "1.2em",
          top: 0,
          left: 0,
          zIndex: 10,
          cursor: "pointer",
          width: "1em",
          height: "1em",
        }}
        onClick={decoratedOnClick}
        icon={faArrowDown}
      />
    </div>
  );
}

const PDDesigner = (props) => {
  //const [show, setShow] = useState(true);
  var selected_designer_path = cookies.get("designer.preference");
  var selected_designer_id = cookies.get("designer.preferenceID");
  const { isAuthenticated, user, loading, getAccessTokenSilently } = useAuth0();
  const [expanded, setExpanded] = useState(false);
  const [url, seturl] = useState();
  const [designer_id, set_designer_id] = useState();
  const [designer_state, set_designer_state] = useState();
  const [menuWidth, setMenuWidth] = useState(0);
  // set_designer_id(selected_designer_id);
  // For AB testing
  // isUserWhiteListed = !!USERS_WHITELIST.find((email) => user.email === email);
  isUserWhiteListed = true;
  Iframestyle = isUserWhiteListed
    ? {
        width: expanded ? `calc(100% - ${menuWidth}px)` : "100%",
        left: `${menuWidth}px`,
      }
    : { height: expanded ? "calc(100% - 70px)" : "100%" };
  function startAWSDesigner(selected_designer_id) {
    let designer_to_start = `${selected_designer_id}`;
    //alert(designer_to_start);
    setDesignerState(selected_designer_id).then(([data]) => {
      // both have loaded!
      //console.log(data);
      if (data.status == 400) {
        alert(data.message);
      } else if (data.status == 200) {
        window.location.reload();
      }
    });
  }

  function setDesignerState(selected_designer_id) {
    return Promise.all([setState(selected_designer_id)]);
  }

  async function setState(selected_designer_id) {
    //var user_designer_id = cookies.get("designer.preference");
    const accessToken = await getAccessTokenSilently();

    const requestOptions = {
      method: "POST",
      headers: new Headers({
        authtoken: selected_designer_id,
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    return fetch(
      config.REACT_APP_BACKEND_API + "/awshelper",
      requestOptions
      //body: "A=1&B=2",
    ).then((response) => response.json());
  }

  async function setDesignerSession(selected_designer_id, user_email) {
    //var user_designer_id = cookies.get("designer.preference");
    const accessToken = await getAccessTokenSilently();

    const requestOptions = {
      method: "POST",
      headers: new Headers({
        authtoken: selected_designer_id,
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      }),
      body: "user=" + user_email,
    };
    return fetch(
      config.REACT_APP_BACKEND_API + "/setdesignersession",
      requestOptions
      //body: "A=1&B=2",
    ).then((response) => response.json());
  }

  const onMenuToggled = (open, width) => {
    if (!open) {
      setMenuWidth(width);
    } else {
      setMenuWidth(0);
    }
    setExpanded(!open);
  };

  //var selected_designer_id = cookies.get("designer.preferenceID");
  function getDesignerandState(selected_designer_id) {
    //console.log("called API" + selected_designer_id);
    return Promise.all([getDesignerState(selected_designer_id)]);
  }
  async function getDesignerState(selected_designer_id) {
    //var user_designer_id = cookies.get("designer.preference");
    const accessToken = await getAccessTokenSilently();
    const requestOptions = {
      method: "POST",
      headers: new Headers({
        authtoken: selected_designer_id,
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    return fetch(
      config.REACT_APP_BACKEND_API + "/getdesignerstate",
      requestOptions
      //body: "A=1&B=2",
    ).then((response) => response.json());
  }
  useEffect(() => {
    if (!loading && user) {
      const getUserMetadata = async () => {
        const domain = config.REACT_APP_AUTH0_DOMAIN;

        try {
          const accessToken = await getAccessTokenSilently();
          let coookiedate = new Date(); // Now
          coookiedate.setDate(coookiedate.getDate() + 30);
          cookies.set("designer.jwt.token", splitJwtToken(accessToken).claim, {
            secure: config.REACT_APP_SECURE_COOKIE === "false" ? false : true,
            domain: config.REACT_APP_COOKIE_URL,
            expires: coookiedate,
            // sameSite: "none",
          });
          let user_id = user.sub.split("|")[1];
          const userDetailsByIdUrl =
            config.REACT_APP_BACKEND_API + "/getuser/" + user_id;

          const metadataResponse = await fetch(userDetailsByIdUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          let user_app_data = await metadataResponse.json();

          let user_app_path = selected_designer_path;

          if (user_app_path == undefined) {
            user_app_path = user_app_data.designer_path;
            cookies.set("designer.preference", user_app_path);
            cookies.set("designer.preferenceID", user_app_data.designer_id);
            selected_designer_id = user_app_data.designer_id;
          } else {
            user_app_path = selected_designer_path;
          }
          let url =
            config.REACT_APP_DESIGNER_URL +
            user_app_path +
            "?access_token=" +
            splitJwtToken(accessToken).signature;
          if (user_app_path.startsWith("https://")) {
            url =
              user_app_path +
              "?access_token=" +
              splitJwtToken(accessToken).signature;
          }
          seturl(url);
        } catch (e) {
          console.log(e.message);
        }
      };
      getUserMetadata().then(() => {
        getDesignerandState(selected_designer_id)
          .then(([state]) => {
            // both have loaded!
            //console.log(state);
            // console.log(state["result"]["designer_state"]);
            //console.log(":::state here::::", state);
            set_designer_state(state["result"]["designer_state"]);

            //set_designer_state(0);
          })
          .catch((error) => {
            set_designer_state(1);
          });
      });
    }
  }, [loading, user]);

  const renderSideNav = (variant = "") => (
    <NavBar variant={variant} OnMenuToggled={onMenuToggled} />
  );

  const renderTopNav = (variant = "", expanded, setExpanded) => (
    <Accordion>
      <CustomToggle expanded={expanded} setExpanded={setExpanded} eventKey="0">
        Click me!
      </CustomToggle>
      <Accordion.Collapse eventKey="0">
        <TopNavBar variant={variant} />
      </Accordion.Collapse>
    </Accordion>
  );

  const renderDesigner = (url, isAuthenticated, expanded, setExpanded) => {
    return (
      <>
        {isAuthenticated && (
          <div className={styles.PDDesigner}>
            {isUserWhiteListed
              ? renderSideNav("designer")
              : renderTopNav("designer", expanded, setExpanded)}
            <div style={Iframestyle} className={styles.IframeWrapper}>
              <Iframe
                url={url}
                frameBorder="0"
                id="myIframe"
                allow="clipboard-read; clipboard-write"
                className={styles.Iframe}
              />
            </div>
          </div>
        )}
      </>
    );
  };
  const renderBlankDesigner = (
    url,
    selected_designer_id,
    isAuthenticated,
    expanded,
    setExpanded,
    startAWSDesigner
  ) => {
    return (
      <>
        {isAuthenticated && (
          <div className={styles.PDDesigner}>
            {isUserWhiteListed
              ? renderSideNav("")
              : renderTopNav("", expanded, setExpanded)}
            <div style={Iframestyle} className={styles.IframeWrapper}>
              <DesignerWarning />
              <div className={styles.outPopUpcenter}>
                <div className={styles.outPopUpTextColor}>
                  <h3>Your Designer is currently stopped</h3>
                  <p className={styles.outPopUpTextColor}>
                    Click the button below to start your Designer
                  </p>
                  <Button
                    variant="primary"
                    onClick={() => startAWSDesigner(selected_designer_id)}
                  >
                    {/* <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> */}
                    Start Designer
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const renderloadin = (
    messageHeader,
    message,
    isAuthenticated,
    expanded,
    setExpanded
  ) => {
    return (
      <>
        {isAuthenticated && (
          <div className={styles.PDDesigner}>
            {isUserWhiteListed
              ? renderSideNav("")
              : renderTopNav("", expanded, setExpanded)}
            {/* <div
              style={{ width: expanded ? `calc(100% - ${menuWidth}px)` : "100%" }}
              className={styles.IframeWrapper}
            > */}

            <div className={styles.outPopUpcenter}>
              <div className={styles.outPopUpTextColor}>
                <h3 className={styles.outPopUpTextColor}>{messageHeader}</h3>
                <p>{message}</p>
                <Spinner
                  className={styles.outPopUp}
                  animation="border"
                  variant="primary"
                />
              </div>
            </div>

            {/* </div> */}
          </div>
        )}
      </>
    );
  };

  if (!loading && designer_state == 1) {
    setDesignerSession(selected_designer_id, user.email).then(() => {});
    function updateDesignerSession() {
      setDesignerSession(selected_designer_id, user.email).then(() => {});
    }

    setInterval(updateDesignerSession, 900000); // Time in milliseconds
    return (
      <div>{renderDesigner(url, isAuthenticated, expanded, setExpanded)}</div>
    );
  } else if (!loading && designer_state == 0) {
    return (
      <div>
        {renderBlankDesigner(
          url,
          selected_designer_id,
          isAuthenticated,
          expanded,
          setExpanded,
          startAWSDesigner
        )}
      </div>
    );
  } else if ((!loading && designer_state == 2) || designer_state == 4) {
    function checkstate() {
      getDesignerandState(selected_designer_id).then(([state]) => {
        //console.log(state["result"]["designer_state"]);
        if (state["result"]["designer_state"] == 1) {
          window.location.reload();
          // setTimeout(function () {
          //   window.location.reload();
          // }, 7000);
        }
      });
    }

    setInterval(checkstate, 20000); // Time in milliseconds
    let messageHeader = "Starting Designer";
    if (designer_state == 4) {
      messageHeader = "Updating Designer";
    }
    let message = "This takes a couple of minutes...";
    return (
      <div>
        {renderloadin(
          messageHeader,
          message,
          isAuthenticated,
          expanded,
          setExpanded
        )}
      </div>
    );
  } else {
    let messageHeader = "Starting Designer";
    let message = "This takes a couple of minutes...";
    return (
      <div>
        {renderloadin(
          messageHeader,
          message,
          isAuthenticated,
          expanded,
          setExpanded
        )}
      </div>
    );
  }
};

export default withAuthenticationRequired(PDDesigner, {
  onRedirecting: () => (
    <Spinner className={styles.styleSpinner} animation="grow" />
  ),
});
