import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import AppContext from '../../../context/app';
import config from '../../../config';

import styles from "./Footer.module.css";

var FA = require("react-fontawesome");

function Footer() {
  const { isAuthenticated } = useAuth0();

  return (
    <Container fluid className={styles.Footer}>
      <Row>
        <Col md={6}>
          <Link to="/">
            <img
              alt="footer_logo"
              src={
                config.REACT_APP_PUBLIC_URL +
                "assets/img/footer/PD_Logo_White.png"
              }
            />
          </Link>
          <div className={styles.icon}>
            <a href="tel:1-617-528-9685">
              <FA name="phone" className="white icon" />
            </a>
            <a href="tel:1-617-528-9685">
              <p className="white">1-617-528-9685</p>
            </a>
          </div>
          <div className={styles.icon}>
            <a href="mailto:support@prescientdevices.com">
              {" "}
              <FA name="envelope" className="white icon" />
            </a>
            <a href="mailto:support@prescientdevices.com">
              <p className="white">support@prescientdevices.com</p>
            </a>
          </div>
          <div className={styles.icon}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/prescientdevices/"
            >
              <FA name="linkedin" className="white icon" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.linkedin.com/company/prescientdevices/"
            >
              <p className="white">@prescientdevices</p>
            </a>
          </div>
          <div className={styles.icon}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/pdi_iot"
            >
              <FA name="twitter" className="white icon" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://twitter.com/pdi_iot"
            >
              <p className="white">@pdi_iot</p>
            </a>
          </div>

          {/* <div className={styles.social_icon}>
            <a target="_blank" href="https://twitter.com/pdi_iot">
              <FA name="twitter" className="white icon" />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/prescientdevices/"
            >
              {" "}
              <FA name="linkedin" className="white icon" />
            </a>
          </div> */}
        </Col>
        <Col md={6}>
          <Row className={styles.sections}>
            <Col sm={4} xs={6}>
              <h1>Company</h1>
              <p>
                <Link to="/about">About</Link>
              </p>
              <p>
                <Link to="/contact">Contact</Link>
              </p>
              {/* <p>Terms of Use</p> */}
              <p>
                {" "}
                <Link to="/privacy">Privacy Policy</Link>
              </p>
              {/* <p>Sitemap</p> */}
            </Col>
            <Col sm={4} xs={6}>
              <h1>Product</h1>
              <p>
                <Link to="/designer">Designer</Link>
              </p>
              <p>
                <Link to="/security">Security</Link>
              </p>
              <p>
                <Link to="/case-studies">Case Studies</Link>
              </p>
              <p>
                <Link to="/adoption">Adoption Program</Link>
              </p>
              <p>
                <Link to="/why-prescient">Why Prescient</Link>
              </p>
            </Col>
            <Col sm={4} xs={12}>
              <h1>Resources</h1>
              {isAuthenticated && (
                <Link to="/documentation">
                  <p>Documentation</p>
                </Link>
              )}
              <Link to="/blog">
                <p>Blog</p>
              </Link>
              <Link to="/careers">
                <p>Careers</p>
              </Link>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/prescientdevices/"
              >
                <p>LinkedIn</p>
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/pdi_iot"
              >
                <p>Twitter</p>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={styles.copyRightSection}>
        <Col
          lg={{ span: 10, order: 1 }}
          md={{ span: 9, order: 1 }}
          xs={{ span: 12, order: 2 }}
        >
          <p>{"© 2020 Prescient Devices, Inc."}</p>
        </Col>
        <Col
          md={{ span: 2, order: 2 }}
          lg={{ span: 2, order: 2 }}
          xs={{ span: 12, order: 1 }}
        >
          <AppContext.Consumer>
            {
              context => <Button onClick={context.gotoContactPage}>Contact Us</Button>
            }
          </AppContext.Consumer>

        </Col>
      </Row>
    </Container>
  );
}

export default Footer;