import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useLocation, useHistory } from "react-router-dom";
import { NavDropdown, Navbar, Nav, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useNotification } from "../../hooks";
import Modal from "../UI/Modal/Modal";
import AppContext from "../../context/app";
import config, { USERS_WHITELIST } from "../../config";

import styles from "./TopDesignerNavBar.module.css";
import { faKaaba } from "@fortawesome/free-solid-svg-icons";
import Notification from "../UI/Notification/Notification";

const cookies = new Cookies();

const TopNavBar = (props) => {
  const [loading, setLoading] = useState(false);
  const { notificationProps, showNotification } = useNotification();
  const { isAuthenticated, loginWithRedirect, user, getAccessTokenSilently } =
    useAuth0();
  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { pathname } = useLocation();

  const history = useHistory();

  const UPDATE_MESSAGE =
    "Would you like to Update Designer? This operation might take a few minutes during which Designer will be inaccessible. Any changes that have not been deployed will be lost. After Designer update the browser window will automatically refresh.";

  useEffect(() => {
    setExpanded(false);
  }, [pathname]);

  //const [appId, setAppId] = useState(null);
  const [app_designers, setDesigners] = React.useState([]);
  useEffect(async () => {
    if (!loading && user) {
      const getUserMetadata = async () => {
        const accessToken = await getAccessTokenSilently();
        let user_id = user.sub.split("|")[1];

        const userDetailsByIdUrl =
          config.REACT_APP_BACKEND_API + "/getuser/" + user_id;
        //const userDetailsByIdUrl = `https://${config.REACT_APP_AUTH0_DOMAIN}/api/v2/users/${user.sub}`;

        const metadataResponse = await fetch(userDetailsByIdUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        let user_app_data = await metadataResponse.json();
        //console.log(user_app_data);
        return user_app_data;
      };
      var data = await getUserMetadata();
      //const appId = data.designer_path;
      //setAppId(appId);
      // console.log(data.designers);
      setDesigners(data.designers);
    }
  }, []);

  const confirmUpdateDesigner = () => {
    setShowModal(true);
  };

  const updateDesigner = () => {
    var selected_designer_id = cookies.get("designer.preferenceID");
    //alert(designer_to_start);
    setIsLoading(true);
    updateDesignerFromAWS(selected_designer_id).then(
      ([data]) => {
        // both have loaded!
        //console.log(data);
        if (data.status == 400) {
          showNotification(data.message);

          setIsLoading(false);
          setShowModal(false);
        } else if (data.status == 200) {
          //Confirm Box with the message and then reload
          /*
        Would you like to Update Designer?       
This operation might take a few minutes during which Designer will be inaccessible
Any changes that have not been deployed will be lost
After Designer update the browser window will automatically refresh
        */
          setIsLoading(false);
          setShowModal(false);
          window.location.reload();
        }
      },
      () => {
        setIsLoading(false);
        setShowModal(false);
      }
    );
  };

  function updateDesignerFromAWS(selected_designer_id) {
    return Promise.all([callUpdate(selected_designer_id)]);
  }

  async function callUpdate(selected_designer_id) {
    //var user_designer_id = cookies.get("designer.preference");
    const accessToken = await getAccessTokenSilently();

    const requestOptions = {
      method: "POST",
      headers: new Headers({
        authtoken: selected_designer_id,
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    return fetch(
      config.REACT_APP_BACKEND_API + "/updatedesigner",
      requestOptions
      //body: "A=1&B=2",
    ).then((response) => response.json());
  }

  const gotoContactPage = () => {
    window.open(
      "https://www.prescientdevices.com/en-us/contact-us-prescient-devices"
    );
  };

  const gotoDocumentationPage = () => {
    window.open("https://docs.prescientdevices.com");
  };

  const onToggle = () => {
    setExpanded((expanded) => !expanded);
  };

  const onDropDownItemSelect = () => {
    setExpanded(false);
  };

  const getSelectedItem = () => {
    try {
      var selected_designer_path = cookies.get("designer.preference");
      var designer_link = document.getElementById(selected_designer_path);
      designer_link.style = "color: white !important;";
      designer_link.style.backgroundColor = "#003C83";
    } catch (error) {
      console.log(error);
    }
  };

  const setDesignerPreferenceCookie = (designer_path, designer_id) => {
    cookies.set("designer.preference", designer_path);
    cookies.set("designer.preferenceID", designer_id);
    window.location.reload();
  };

  return (
    <>
      <Notification {...notificationProps} />
      <Navbar
        collapseOnSelect
        expand="lg"
        className={styles.navBar}
        expanded={expanded}
      >
        <Navbar.Brand href={config.REACT_APP_MAIN_URL}>
          <img
            alt="sub-logo"
            src={config.REACT_APP_PUBLIC_URL + "assets/img/logo.png"}
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={onToggle}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto" />
          <Nav className={styles.navBarRight}>
            {!isAuthenticated && (
              <Button
                variant="outline-secondary"
                onClick={() => loginWithRedirect({})}
              >
                Login
              </Button>
            )}
            {/* <Button variant="outline-secondary" onClick={gotoLoginPage}>
      Login
    </Button> */}
            {!isAuthenticated && (
              <Button variant="secondary" onClick={gotoContactPage}>
                Request a Demo
              </Button>
            )}
            {isAuthenticated &&
            props.variant != "documentation" &&
            app_designers.length > 1 &&
            props.variant != "dashboard" ? (
              <NavDropdown
                onClick={getSelectedItem}
                renderMenuOnMount
                title="Select Designer"
                id="collasible-nav-dropdown"
                className={styles.navDropdown}
                rootCloseEvent="click"
                onSelect={onDropDownItemSelect}
              >
                {app_designers.map(
                  ({ designer_name, designer_path, designer_id }) => (
                    <LinkContainer
                      to={{
                        pathname: "/pddesigner",
                        state: {
                          path: designer_path,
                        },
                      }}
                      onSelect={() =>
                        setDesignerPreferenceCookie(designer_path, designer_id)
                      }
                      // to="/pddesigner"
                    >
                      <Nav.Link
                        isActive={false}
                        exact
                        activeClassName="active"
                        id={designer_path}
                      >
                        {designer_name}
                      </Nav.Link>
                    </LinkContainer>
                  )
                )}
              </NavDropdown>
            ) : (
              <p>&nbsp;</p>
            )}
            {isAuthenticated &&
              props.variant != "documentation" &&
              props.variant != "dashboard" && (
                <Button onClick={confirmUpdateDesigner}>Update</Button>
              )}

            {isAuthenticated &&
              (props.variant === "dashboard" ? (
                <AppContext.Consumer>
                  {(context) => (
                    <Button
                      variant="secondary"
                      onClick={context.gotoDesignerPage}
                    >
                      Designer
                    </Button>
                  )}
                </AppContext.Consumer>
              ) : (
                <AppContext.Consumer>
                  {(context) => (
                    <Button
                      variant="secondary"
                      onClick={context.gotoDashboardPage}
                    >
                      Dashboard
                    </Button>
                  )}
                </AppContext.Consumer>
              ))}
            {isAuthenticated &&
              (props.variant === "documentation" ? (
                <AppContext.Consumer>
                  {(context) => (
                    <Button
                      variant="secondary"
                      onClick={context.gotoDesignerPage}
                    >
                      Designer
                    </Button>
                  )}
                </AppContext.Consumer>
              ) : (
                <AppContext.Consumer>
                  {(context) => (
                    <Button variant="secondary" onClick={gotoDocumentationPage}>
                      Documentation
                    </Button>
                  )}
                </AppContext.Consumer>
              ))}
            {/* {isAuthenticated && (
              <Button variant="secondary" onClick={gotoDocumentationPage}>
                Documentation
              </Button>
            )} */}
            {isAuthenticated && (
              <AppContext.Consumer>
                {(context) => {
                  return (
                    <Button
                      variant="outline-secondary"
                      onClick={context.logoutPD}
                    >
                      Log out
                      {/* {user.nickname} */}
                    </Button>
                  );
                }}
              </AppContext.Consumer>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {showModal && (
        <Modal
          variant="confirm"
          message={UPDATE_MESSAGE}
          onConfirmClicked={updateDesigner}
          onCancelClicked={() => setShowModal(false)}
          isLoading={isLoading}
          loadingMessage="Looking for updates"
        />
      )}
    </>
  );
};

export default TopNavBar;
